import { render, staticRenderFns } from "./notificationComponent.vue?vue&type=template&id=7730984e&scoped=true&"
import script from "./notificationComponent.vue?vue&type=script&lang=js&"
export * from "./notificationComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7730984e",
  null
  
)

export default component.exports