<template>
	<div class="container mb-3">
		<div class="row flex flex-col md:flex-row justify-between items-center align-items-center mb-3">
			<div></div>
			<div
				class="flex flex-col md:flex-row w-11/12 md:w-auto justify-between align-items-center uppercase border rounded-md bg-white text-xs md:text-xs">
					<span data-cy='tab1' @click="showTab(1)"
						  class="v-company-setting-2 text-xs h-8 w-full md:w-auto flex justify-center align-items-center cursor-pointer px-3 py-2 md:rounded-l-md"
						  :class="tab === 1 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
							$t('generalSetting')
						}}</span>
				<span data-cy='tab1' @click="showTab(2)"
					  class="v-company-setting-2 text-xs h-8 w-full md:w-auto flex justify-center align-items-center cursor-pointer px-3 py-2"
					  :class="tab === 2 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
						$t('customerManagement')
					}}</span>
				<span data-cy='tab2' @click="showTab(3)"
					  class="v-company-setting-3 text-xs h-8 w-full md:w-auto flex justify-center align-items-center cursor-pointer px-3 py-2"
					  :class="tab === 3 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
						$t('stockManagement')
					}}</span>
				<span data-cy='tab3' @click="showTab(4)"
					  class="v-company-setting-4 text-xs h-8 w-full md:w-auto flex justify-center align-items-center cursor-pointer px-3 py-2 md:rounded-r-md"
					  :class="tab === 4 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
						$t('notification')
					}}</span>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<general-setting v-if="tab === 1"/>
				<customer-management v-if="tab === 2"/>
				<stock-management v-if="tab === 3"/>
				<notification-component v-if="tab===4"/>
			</div>
		</div>
	</div>
</template>

<script>
import customerManagement from "./companySettings/customerManagement";
import generalSetting from "./companySettings/generalSetting";
import notificationComponent from "./companySettings/notificationComponent";
import stockManagement from "./companySettings/stockManagement";

export default {
	name: "companySettingComponent",
	components: {
		customerManagement,
		generalSetting,
		notificationComponent,
		stockManagement
	},
	data: function () {
		return {
			billing: {
				name: '',
				organization_number: '',
				address: '',
				city: '',
				phone_number: '',
				post_invoice: false,
				shop_url: '',
				contact_email: '',
				id: '',
				zip_code: '',
				invoice_email: '',
				brand_name: '',
				invite_email_subject: '',
				invite_email_header: '',
				invite_email_content: '',
				order_confirmation_text: '',
				use_accounting_customer_number_for_identifier: '',
				menu_color: '',
				button_color: '',
				link_color: '',
				price_list: {
					id: ''
				},
				bankgiro: '',
				plusgiro: '',
				iban: '',
				apply_specific_customer_max_credit: false,
				apply_general_max_credit: false,
				general_max_credit_amount: 0,
				apply_stock_threshold: false,
				stock_threshold_quantity: 0,
				require_bank_id_for_all_orders: false,
				display_store_to_all_buyers: false,
				require_bank_id_for_for_orders_above_certain_amount: 0,
				send_invoices_via_noxfinance: 0,
				bankid_order_amount: 0,
				minimum_order_amount: 0,
				hide_stock_quantity: 0,
				admin_email_address: '',
				send_order_notifications_to_admin: false,
				disable_customer_order_conf_emails: false,
				show_standard_price_additional_to_customer_specific_price: false,
				customer_invite_rule: '',
			},
			tab: 1
		}
	},
	methods: {
		showTab: function (tab) {
			this.tab = parseInt(tab)

		}
	}
}
</script>

<style scoped>

</style>
