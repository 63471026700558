<template>
	<div class="row space-y-1 bg-white p-4">
		<div class="col-md-6">
			<div class="form-group">
				<label
					class="flex align-items-center col-form-label font-light whitespace-nowrap ">{{
						$t('customerInviteReminder')
					}}<span class="help-tooltip cursor-pointer"
							v-bind:title="$t('customEmailRuleHelpText')"></span></label>
				<div>
					<el-select v-model="customer_invite_rule"
							   :placeholder="$t('selectRule')" style="width: 60%;">
						<el-option
							:key="`once_in_a_week`"
							:label="$t('once_in_a_week')"
							:value="`once_in_a_week`">
						</el-option>
						<el-option
							:key="`twice_in_a_week`"
							:label="$t('twice_in_a_week')"
							:value="`twice_in_a_week`">
						</el-option>
						<el-option
							:key="`three_time_in_a_week`"
							:label="$t('three_time_in_a_week')"
							:value="`three_time_in_a_week`">
						</el-option>
						<el-option
							:key="`dont_send_reminder`"
							:label="$t('dont_send_reminder')"
							:value="`do_not_send_reminder`">
						</el-option>

					</el-select>
				</div>
			</div>
		</div>
		<div class="col-md-6">
			<div class="row no-gutters">
				<div class="col-12">
					<div class="form-group">
						<label
							class="label-w-input col-form-label font-light whitespace-nowrap  mb-1">
							<input data-cy='apply-send_order_notifications_to_admin'
								   type="checkbox"
								   id="send_order_notifications_to_admin"
								   v-model="billing.send_order_notifications_to_admin"
								   class="checkbox-height  h-4 w-4">
							<span class="ml-2">{{ $t('send_order_notifications_to_admin') }}<span
								class="help-tooltip cursor-pointer"
								v-bind:title="$t('sendEmailToAdminHelpText')"></span></span>
						</label>
						<div v-if="billing.send_order_notifications_to_admin === true">
							<input data-cy='admin_email_address' type="email"
								   style="width:100% !important"
								   v-model="billing.admin_email_address"
								   :placeholder="$t('adminEmailAddress')"
								   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-6">
			<div class="form-group">
				<label
					class="label-w-input col-form-label font-light whitespace-nowrap  mb-1">
					<input data-cy='apply-send_order_notifications_to_admin'
						   type="checkbox"
						   id="send_order_notifications_to_admin"
						   v-model="billing.disable_customer_order_conf_emails"
						   class="checkbox-height  h-4 w-4">
					<span class="ml-2">{{ $t('disable_customer_order_conf_emails') }}<span
						class="help-tooltip cursor-pointer"
						v-bind:title="$t('dontSendOrderConfirmationToBuyerText')"></span></span>
				</label>
			</div>
		</div>
		<div class="col-md-12 mt-5 mb-4">
                                    <span class="flex justify-center">
                                        <button data-cy='setting-btn' @click="updateSettings" :disabled="updating"
												:style="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? 'background:' + GET_CUSTOMIZATION.button : ''"
												:class="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? '' : 'bg-green-500'"
												class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-11/12 md:w-1/3">
                                            <span v-if="updating" class="flex align-items-center space-x-3">
                                                <svg class="animate-spin mr-2 h-5 w-5 text-white"
													 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
														stroke-width="4"></circle>
                                                <path class="opacity-75" fill="currentColor"
													  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                                {{ $t('updating') }}
                                            </span>
                                            <span v-else>{{ $t('update') }}</span>
                                        </button>
                                    </span>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "notificationComponent",
	data: function () {
		return {
			billing: {
				name: '',
				organization_number: '',
				address: '',
				city: '',
				phone_number: '',
				post_invoice: false,
				shop_url: '',
				contact_email: '',
				id: '',
				zip_code: '',
				invoice_email: '',
				brand_name: '',
				invite_email_subject: '',
				invite_email_header: '',
				invite_email_content: '',
				order_confirmation_text: '',
				use_accounting_customer_number_for_identifier: '',
				menu_color: '',
				button_color: '',
				link_color: '',
				price_list: {
					id: ''
				},
				bankgiro: '',
				plusgiro: '',
				iban: '',
				apply_specific_customer_max_credit: false,
				apply_general_max_credit: false,
				general_max_credit_amount: 0,
				apply_stock_threshold: false,
				stock_threshold_quantity: 0,
				require_bank_id_for_all_orders: false,
				display_store_to_all_buyers: false,
				require_bank_id_for_for_orders_above_certain_amount: 0,
				send_invoices_via_noxfinance: 0,
				bankid_order_amount: 0,
				minimum_order_amount: 0,
				hide_stock_quantity: 0,
				admin_email_address: '',
				send_order_notifications_to_admin: false,
				disable_customer_order_conf_emails: false,
				show_standard_price_additional_to_customer_specific_price: false,
				customer_invite_rule: '',
			},
			croppedImage: null,
			updating: false,
			customer_invite_rule: ''
		}
	},
	methods: {
		changeBooleanField(s, e) {
			if (!this.billing[s]) {
				this.billing[e] = 0
			}
		},
		openLogoModal() {
			this.$store.commit('utility/OPEN_PHOTO_MODAL')
		},
		updateSettings() {

			if (this.billing.send_order_notifications_to_admin === true) {
				if (this.billing.admin_email_address === '') {
					this.$services.helpers.notification(this.$t('adminEmailAddressError'), 'error', this)
					return false
				}
			} else {
				this.billing.admin_email_address = ''
			}

			this.updating = true
			const payload = {
				brand_name: this.billing.brand_name,
				store_description: this.billing.store_description,
				post_invoice: this.billing.post_invoice,
				apply_customer_pricelist: this.billing.apply_customer_pricelist,
				apply_specific_customer_max_credit: this.billing.apply_specific_customer_max_credit,
				apply_general_max_credit: this.billing.apply_general_max_credit,
				general_max_credit_amount: this.billing.general_max_credit_amount,
				apply_stock_threshold: this.billing.apply_stock_threshold,
				use_accounting_customer_number_for_identifier: this.billing.use_accounting_customer_number_for_identifier,
				// stock_threshold_quantity : this.billing.stock_threshold_quantity,
				require_bank_id_for_all_orders: this.billing.require_bank_id_for_all_orders,
				require_bank_id_for_for_orders_above_certain_amount: this.billing.require_bank_id_for_for_orders_above_certain_amount,
				bankid_order_amount: this.billing.bankid_order_amount,
				threshold_product_visibility: this.billing.threshold_product_visibility,
				send_invoices_via_noxfinance: this.billing.send_invoices_via_noxfinance,
				display_store_to_all_buyers: this.billing.display_store_to_all_buyers,
				minimum_order_amount: this.billing.minimum_order_amount,
				hide_stock_quantity: this.billing.hide_stock_quantity,
				send_order_notifications_to_admin: this.billing.send_order_notifications_to_admin,
				admin_email_address: this.billing.admin_email_address,
				disable_customer_order_conf_emails: this.billing.disable_customer_order_conf_emails,
				customer_invite_rule: this.billing.customer_invite_rule,
				show_standard_price_additional_to_customer_specific_price: this.billing.show_standard_price_additional_to_customer_specific_price,
			}

			this.$store.dispatch('portal/updateCompanySettings', payload)
				.then(res => {
					this.updating = false
					this.$services.helpers.notification(this.$t('successCompanyupdated'), 'success', this)

				}).catch((err) => {
				this.updating = false
				window.Bus.$emit('output-error', err)
			})
		},
		getCompanyDetails() {
			this.$store.dispatch('portal/getCompanyDetails')
				.then(res => {
					this.billing = res.data
				})
				.catch(err => {
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_USER', null)
						window.Bus.$emit('sign-out')
					}
				})
		},
	},
	computed: {
		...mapGetters({
			GET_CUSTOMIZATION: 'portal/GET_CUSTOMIZATION',
		})
	},
	mounted() {
		this.getCompanyDetails()
	}
}
</script>

<style scoped>

</style>
