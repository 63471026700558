<template>
	<div class="row space-y-1 bg-white p-4">
		<div class="col-md-6">
			<div class="form-group">
				<label class="label-w-input col-form-label font-light mb-1">
					<input data-cy='bank-id-threshold-amount' type="checkbox"
						   v-model="billing.hide_stock_quantity"
						   class="checkbox-height   h-4 w-4">
					<span class="ml-2">{{ $t('hide_stock_quantity') }}<span
						class="help-tooltip cursor-pointer"
						v-bind:title="$t('hide_stock_quantity')"></span></span>
				</label>
			</div>
		</div>
		<div class="col-md-6">
			<div class="form-group row">
				<label
					class="label-w-input col-form-label font-light whitespace-nowrap  mb-1 col-md-5">
					<input type="checkbox" id="applyStockThreshold"
						   @change="changeBooleanField('apply_stock_threshold','stock_threshold_quantity')"
						   v-model="billing.apply_stock_threshold"
						   class="checkbox-height  h-4 w-4">
					<span class="ml-2">{{ $t('applyStockThreshold') }}<span
						class="help-tooltip cursor-pointer"
						v-bind:title="$t('applyStockThresholdHelpText')"></span></span>
				</label>
				<div class="col-md-7" v-if="billing.apply_stock_threshold === true">
					<input data-cy='threshold-qty' type="number" min="0" :placeholder="$t('stockThresholdQuantity')"
						   style="width:60% !important"
						   v-model="billing.threshold_product_visibility"
						   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
				</div>

			</div>
		</div>
		<div class="col-md-6">
			<div class="form-group">
				<label class="label-w-input col-form-label font-light mb-1">
					<input data-cy='sync_only_webshop_articles' type="checkbox"
						   v-model="billing.sync_only_webshop_articles"
						   class="checkbox-height   h-4 w-4">
					<span class="ml-2">{{ $t('sync_only_webshop_articles') }}<span
						class="help-tooltip cursor-pointer"
						v-bind:title="$t('sync_only_webshop_articles')"></span></span>
				</label>
			</div>
		</div>
		<div class="col-md-6" style="display: none !important;">
			<div class="form-group">
				<label class="label-w-input col-form-label font-light mb-1">
					<span>{{ $t('stockpoint') }}<span
						class="help-tooltip cursor-pointer"
						v-bind:title="$t('stockpoint')"></span></span>
				</label>
				<el-select v-model="stockpoint"
						   :placeholder="$t('selectRule')" style="width: 60%;">
					<el-option
						:key="`once_in_a_week`"
						:label="$t('once_in_a_week')"
						:value="`once_in_a_week`">
					</el-option>
					<el-option
						:key="`twice_in_a_week`"
						:label="$t('twice_in_a_week')"
						:value="`twice_in_a_week`">
					</el-option>
					<el-option
						:key="`three_time_in_a_week`"
						:label="$t('three_time_in_a_week')"
						:value="`three_time_in_a_week`">
					</el-option>
					<el-option
						:key="`dont_send_reminder`"
						:label="$t('dont_send_reminder')"
						:value="`do_not_send_reminder`">
					</el-option>

				</el-select>

			</div>
		</div>


		<div class="col-md-12 mt-5 mb-4">
                                    <span class="flex justify-center">
                                        <button data-cy='setting-btn' @click="updateSettings" :disabled="updating"
												:style="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? 'background:' + GET_CUSTOMIZATION.button : ''"
												:class="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? '' : 'bg-green-500'"
												class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-11/12 md:w-1/3">
                                            <span v-if="updating" class="flex align-items-center space-x-3">
                                                <svg class="animate-spin mr-2 h-5 w-5 text-white"
													 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
														stroke-width="4"></circle>
                                                <path class="opacity-75" fill="currentColor"
													  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                                {{ $t('updating') }}
                                            </span>
                                            <span v-else>{{ $t('update') }}</span>
                                        </button>
                                    </span>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "stockManagement",
	data: function () {
		return {
			billing: {
				name: '',
				organization_number: '',
				address: '',
				city: '',
				phone_number: '',
				post_invoice: false,
				shop_url: '',
				contact_email: '',
				id: '',
				zip_code: '',
				invoice_email: '',
				brand_name: '',
				invite_email_subject: '',
				invite_email_header: '',
				invite_email_content: '',
				order_confirmation_text: '',
				use_accounting_customer_number_for_identifier: '',
				menu_color: '',
				button_color: '',
				link_color: '',
				price_list: {
					id: ''
				},
				bankgiro: '',
				plusgiro: '',
				iban: '',
				apply_specific_customer_max_credit: false,
				apply_general_max_credit: false,
				general_max_credit_amount: 0,
				apply_stock_threshold: false,
				stock_threshold_quantity: 0,
				require_bank_id_for_all_orders: false,
				display_store_to_all_buyers: false,
				require_bank_id_for_for_orders_above_certain_amount: 0,
				send_invoices_via_noxfinance: 0,
				bankid_order_amount: 0,
				minimum_order_amount: 0,
				hide_stock_quantity: 0,
				admin_email_address: '',
				send_order_notifications_to_admin: false,
				disable_customer_order_conf_emails: false,
				show_standard_price_additional_to_customer_specific_price: false,
				customer_invite_rule: '',
				sync_only_webshop_articles: '',
			},
			updating: false,
			stockpoint: ''

		}
	},
	methods: {
		changeBooleanField(s, e) {
			if (!this.billing[s]) {
				this.billing[e] = 0
			}
		},
		updateSettings() {
			if (!this.billing.require_bank_id_for_for_orders_above_certain_amount) {
				this.billing.bankid_order_amount = 0
			}
			this.updating = true
			let payload = {

				threshold_product_visibility: this.billing.threshold_product_visibility,
				hide_stock_quantity: this.billing.hide_stock_quantity,
				apply_stock_threshold: this.billing.apply_stock_threshold,
				sync_only_webshop_articles: this.billing.sync_only_webshop_articles,
			}
			this.$store.dispatch('portal/updateCompanySettings', payload)
				.then(res => {
					this.updating = false
					this.$services.helpers.notification(this.$t('successCompanyupdated'), 'success', this)


				}).catch((err) => {
				this.updating = false
				window.Bus.$emit('output-error', err)
			})
		},
		getCompanyDetails() {
			this.$store.dispatch('portal/getCompanyDetails')
				.then(res => {
					this.billing = res.data
				})
				.catch(err => {
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_USER', null)
						window.Bus.$emit('sign-out')
					}
				})
		},
	},
	computed: {
		...mapGetters({
			GET_CUSTOMIZATION: 'portal/GET_CUSTOMIZATION',
		})
	},
	mounted() {
		this.getCompanyDetails()
	}
}
</script>

<style scoped>

</style>
